import Vue from 'vue';
import App from './App.vue';
import '@/assets/css/common.scss';
import router from './router'
Vue.config.productionTip = false;

new Vue({
	router,
	render: (h) => h(App),
	mounted() {
		document.dispatchEvent(new Event('render-event'));
	}
}).$mount('#app');










import { Vue } from "vue-property-decorator";

export default class App extends Vue {}

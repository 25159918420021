import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '*',
        redirect: '/'
    },
    {
        path: '/',
        name: 'Index',
        meta: {
            keepAlive: true // 缓存位置
        },
        component: () => import('@/views/Index.vue')
    },
    {
        path: '/privacy-policy',
        name: 'privacyPolicy',
        component: () => import('@/views/privacyPolicy.vue')
    }

];

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

export default router; 